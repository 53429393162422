// cSpell: disable
import { useContext, useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router";
import CustomInput from "../customcomponent/custominput";
import "./signin.css";
import { useNavigate } from "react-router";
import AlertPopup from "../Alert/alert";
import { loginstatuscontext } from "../../AppContext";
import mainPicture from "../../images/signin.jpg";

function Signin({ message }) {
    const [Emailaddress, setEmailaddress] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [openlogin, setOpenlogin] = useState(false);
    const login = useContext(loginstatuscontext);

    const location = useLocation();
    const errFromOtherPages = location.state?.error;
    // console.log("MSG hello- ", errFromOtherPages);

    const navigate = useNavigate();

    const handlesignin = useCallback(() => {
        // console.log("signin")
        fetch(process.env.REACT_APP_BASEURL + "/collectors/signin", {
            method: "post",
            body: JSON.stringify({ email: Emailaddress, password }),
            headers: { "content-type": "application/json" },
        })
            .then((res) => res.json())
            .then((data) => {
                if (data["error"]) {
                    setError(data["error"]);
                } else {
                    localStorage.setItem("user", JSON.stringify(data));
                    login.setisLogin(true);
                    const previousurl = localStorage.getItem("previousurl")
                    if (previousurl) {
                        navigate(previousurl)
                    } else {
                        navigate("/gallery");
                    }
                }
            });
    }, [Emailaddress, login, navigate, password]);

    const handleErrorpopup = () => {
        setError(null);
    };

    useEffect(() => {

        const keyDownHandler = event => {
            // console.log('user pressed:', event.key);
            if (event.key === 'Enter') {
                event.preventDefault();
                // console.log(Emailaddress, password)
                handlesignin();
            }
        };

        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [Emailaddress, handlesignin, password]);

    useEffect(() => {
        setOpenlogin(!login.islogin);
    }, [login.islogin]);

    useEffect(() => {
        if (errFromOtherPages)
            setTimeout(() => {
                setOpenlogin(false);
            }, 3000);

    }, [errFromOtherPages]);

    return (
        <>
            <div className="row">
                <div className="col-md-6">
                    <div className="demo-content">
                        <img
                            src={mainPicture}
                            alt=""
                            style={{ width: "100%" }}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="demo-content bg-alt">
                        <div>
                            <div
                                className="mx-auto"
                                style={{
                                    maxWidth: "600px",
                                    padding: "0 2rem",
                                }}>
                                Welcome back to Clifty! Sign in to access your
                                collectibles and rewards gallery.
                                <hr />
                                <br />
                                <h3 className="login-heading mb-4">Sign in</h3>
                                {error && (
                                    <AlertPopup
                                        Message={error}
                                        variant="danger"
                                        setShow={handleErrorpopup}
                                    />
                                )}
                                {openlogin && message && (
                                    <AlertPopup
                                        Message={message}
                                        variant="primary"
                                        setShow={() =>
                                            setOpenlogin(false)
                                        }></AlertPopup>
                                )}
                                {openlogin && errFromOtherPages && (
                                    <AlertPopup
                                        Message={errFromOtherPages}
                                        variant="primary"
                                        setShow={() =>
                                            setOpenlogin(false)
                                        }></AlertPopup>
                                )}

                                <br />
                                <form className="sign">
                                    <CustomInput
                                        label="Email address"
                                        name="Email address"
                                        type="email"
                                        value={Emailaddress}
                                        setValue={setEmailaddress}
                                        onchange={event => setEmailaddress(event.target.value)}
                                        autocomplete="off"
                                    />
                                    <CustomInput
                                        label="Password"
                                        type="password"
                                        name="Password"
                                        value={password}
                                        setValue={setPassword}
                                        onchange={event => setPassword(event.target.value)}
                                        autocomplete="off"
                                    />
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={handlesignin}>
                                        SIGN IN
                                    </button>
                                </form>
                            </div>
                            <br />
                            <div
                                className="col-md-2 col-lg-4 mx-auto"
                                style={{
                                    width: "100%",
                                    textAlign: "center",
                                }}>
                                <p className="styles">
                                    <a href="/user/forgotpassword">
                                        Forgot Password?
                                    </a>
                                </p>
                                <p>
                                    Not a collector? Please{" "}
                                    <a href="/signup">Sign Up</a> here for a
                                    FREE account.
                                    <br />
                                    <br />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Signin;
