import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.css";
import { BrowserRouter } from "react-router-dom";
import { AlertContext, ForgotPasswordprovider, LoginContext } from "./AppContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

// Redirect to https if in production or staging
console.log("NODE_ENV", process.env.NODE_ENV);
console.log("window.location.protocol", window.location.protocol);
console.log("Another REACT_APP_INSTANCE", process.env.REACT_APP_INSTANCE);

if (process.env.NODE_ENV === "production" || process.env.NODE_ENV === "staging") {
  if (window.location.protocol !== "https:") {
    window.location = window.location.href.replace("http://", "https://");
  }
}

root.render(
  <BrowserRouter>
    <AlertContext>
      <LoginContext>
        <ForgotPasswordprovider>
          <App />
        </ForgotPasswordprovider>
      </LoginContext>
    </AlertContext>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
